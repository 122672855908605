import Body from "components/Body";
import Image from "next/image";
import Heading from "components/Heading";
import Section from "components/Section";
import Subheading from "components/Subheading";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { breakpoints, colors, SpaceScale } from "theme";
import Color from "color";

const HelpApp: FC = () => {
  return (
    <Section className={ContainerStyles}>
      <Container>
        <Details>
          <header>
            <Subheading color="apple">
              Why give people crypto instead of cash?
            </Subheading>
            <Heading level={2} color="navy">
              Putting money directly in the hands of our recipients is our
              primary goal. Cryptocurrency offers the best way to do that
            </Heading>
            <Body>
              Crypto democratizes the philanthropic process. We can send
              cryptocurrency directly to any recipient and they’ll receive it
              instantly. This includes people without bank accounts and those
              living in places where fiat money is “broken”.
            </Body>
          </header>
        </Details>
        <Cards>
          <Image
            src="/images/ambassadors/profile-blocks@1.5x.jpg"
            alt="User profiles"
            width={702}
            height={383}
            layout="responsive"
            priority
          />
        </Cards>
      </Container>
    </Section>
  );
};

const Cards = styled.div`
  transform: translateY(-10px);
  margin-bottom: -10px;

  ${breakpoints.mobile} {
    display: none;
  }
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
`;

const Details = styled.div`
  max-width: unset;
  width: 100%;
  ${breakpoints.desktop} {
    width: 60%;
    max-width: 480px;
  }
`;

const ContainerStyles = css`
  background-color: ${Color(colors.sky).alpha(0.2).string()};
  padding: ${SpaceScale(7)} 0;
`;

export default HelpApp;
