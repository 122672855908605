import Body from "components/Body";
import { styled } from "linaria/react";
import { SpaceScale, UnitlessScale } from "src/theme/scale";
import { colors, FontScale } from "theme";

type Props = { color?: keyof typeof colors };
const Subheading = styled(Body)<Props>`
  color: ${({ color = "charcoal" }) => colors[color]};
  font-weight: 600;
  margin-bottom: ${SpaceScale(4)};
  font-size: ${FontScale(4.25)};
  line-height: ${UnitlessScale(4.75)};
  word-break: break-word;
`;

export default Subheading;
