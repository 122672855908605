import React, { FC } from "react";
import Squircle from "components/Squircle";
import { styled } from "linaria/react";
import { breakpoints, SpaceScale } from "theme";

type Props = {
  src: string;
  title: string;
  width?: number;
  height?: number;
  allowFullScreen?: boolean;
  frameBorder?: string;
};
const YoutubePlayer: FC<Props> = ({
  width = 1280,
  height = 720,
  frameBorder = "0",
  allowFullScreen = true,
  ...rest
}) => {
  return (
    <Container>
      <Squircle nature="default">
        <VideoWrapper width={width} height={height}>
          <iframe
            width={width}
            height={height}
            frameBorder={frameBorder}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={allowFullScreen}
            {...rest}
          ></iframe>
        </VideoWrapper>
      </Squircle>
    </Container>
  );
};

const Container = styled.div`
  padding: 0;
  ${breakpoints.tablet} {
    padding: 0 ${SpaceScale(5)};
  }
  ${breakpoints.desktop} {
    padding: 0 ${SpaceScale(8)};
  }
`;
const VideoWrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  padding-bottom: ${({ width, height }) => (height / width) * 100}%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default YoutubePlayer;
