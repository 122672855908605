import { styled } from "linaria/react";
import Flex from "components/Flex";
import React, { FC } from "react";
import { breakpoints, SpaceScale } from "theme";
import useMemoizedChildren from "hooks/useMemoizedChildren";

type Props = any;
const ButtonGroup: FC<Props> = ({ children, $align, $wrap, ...rest }) => {
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <Group {...rest} $wrap={$wrap} $align={$align}>
      {memoizedChildren}
    </Group>
  );
};

const Group = styled(Flex)<{ $align: any; $wrap: boolean }>`
  justify-content: ${({ $align }) => $align ?? "space-between"};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};

  > * {
    &:not(:last-child) {
      margin-right: ${SpaceScale(4)};
    }
  }
`;

export default ButtonGroup;
