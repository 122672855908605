import Body from "components/Body";
import Image from "next/image";
import Button from "components/Button";
import Flex from "components/Flex";
import Heading from "components/Heading";
import Section from "components/Section";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { /* colors,  */ breakpoints, SpaceScale } from "theme";
import Subheading from "components/Subheading";
// import Stats from "./components/Stats";
import Link from "next/link";
import ModalTrigger from "components/Give/GiveTrigger/ModalTrigger";
// import Map from "./components/Map";

const GlobalImpact: FC = () => {
  return (
    <Section background="ivy" color="white" className={GlobeContainer}>
      <Container justify="space-between">
        <Details>
          <header>
            <Subheading color="keyLimePie">Global impact</Subheading>
            <Heading level={2} color="white">
              Our mission is to financially empower individuals by distributing
              cryptocurrency to those most in need.
            </Heading>
          </header>
          <Body color="keyLimePie">
            GiveCrypto connects donors and recipients anywhere in the world.
            Over the past three years, we've impacted the lives of thousands of
            individuals and their families via direct crypto transfers.
          </Body>
        </Details>
        <Activity>
          <Image
            priority
            src="/images/activity/activity@2x.png"
            alt="Picture of Activities"
            width={442}
            height={283}
          />
          {/* <Map /> */}
        </Activity>
      </Container>
      {/* <Stats /> */}
    </Section>
  );
};

const Container = styled(Flex)`
  margin: ${SpaceScale(8)} 0;

  ${breakpoints.mobile} {
    margin: ${SpaceScale(4)} 0;
    flex-wrap: wrap;
  }
`;

const GlobeContainer = css`
  background-image: url("/images/globe/globe@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  ${breakpoints.mobile} {
    padding-bottom: ${SpaceScale(4)};
  }
`;

const Activity = styled.div`
  ${breakpoints.mobile} {
    display: none;
  }
`;

const Details = styled.div`
  max-width: 520px;
  width: 55%;
  margin-bottom: ${SpaceScale(5)};

  ${breakpoints.mobile} {
    width: 100%;
  }
`;

export default GlobalImpact;
