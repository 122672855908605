export { default as Intro } from "./Intro";
export { default as GlobalImpact } from "./GlobalImpact";
export { default as Ambassadors } from "./Ambassadors";
export { default as HelpApp } from "./HelpApp";
export { default as HowItWorks } from "./HowItWorks";
export { default as Video } from "./Video";

const LameExportedFn = () => {
  return "Show page working!";
};

export default LameExportedFn;
