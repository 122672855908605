import React, { FC } from "react";
import PrimaryScene from "icons/scenes/PrimaryScene.svg";
import BlobTop from "icons/BlobTop.svg";
import ChevronRight from "icons/ChevronRight.svg";
import { styled } from "linaria/react";
import { css } from "linaria";
import { breakpoints, colors, depth, FontScale, SpaceScale } from "theme";
import Flex from "components/Flex";
import Heading from "components/Heading";
import Body from "components/Body";
import ButtonGroup from "components/Button/ButtonGroup";
import { MAX_WIDTH } from "src/theme/scale";
import { GiveTrigger } from "components/Give";
import { SignupModalTrigger } from "views/ambassadors/Signup/components/SignupModal";

const Intro: FC = () => {
  return (
    <Container>
      <Scene>
        <Content>
          <Heading
            className={PrimaryHeading}
            level={1}
            color="apple"
            weight={700}
          >
            Give crypto to people in need
          </Heading>
          <Body color="navy">
            GiveCrypto is a nonprofit that distributes cryptocurrency to people
            living in poverty. By using crypto and blockchain technologies, we
            can put funds directly in the hands of the impoverished and the
            unbanked.
          </Body>
          <ButtonGroup
            $wrap
            style={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <GiveTrigger kind="coinbase" />
            {/* <SignupModalTrigger unstyled>
              <Flex center>
                <Body style={{ marginLeft: SpaceScale(2) }} color="navy">
                  Become an ambassador
                </Body>
                <ChevronRight
                  style={{ marginLeft: SpaceScale(2) }}
                  width="8px"
                  height="8px"
                  color={colors.navy}
                />
              </Flex>
            </SignupModalTrigger> */}
          </ButtonGroup>
        </Content>
      </Scene>
      <BottomSection>
        <BlobContainer>
          <BlobTop color={colors.ivy} width="100%" height="200px" />
          <div className="bottom" />
        </BlobContainer>
        <SceneContainer>
          <PrimaryContainer>
            <PrimaryScene width="702px" height="513px" />
          </PrimaryContainer>
        </SceneContainer>
      </BottomSection>
    </Container>
  );
};

const BottomSection = styled.section`
  position: relative;
  ${breakpoints.mobile} {
    margin-top: ${SpaceScale(9.5)};
  }
`;
const SceneContainer = styled.section`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  position: relative;
`;
const Container = styled.section`
  /* min-height: 500px; */
`;
const PrimaryHeading = css`
  font-size: ${FontScale(7.125)} !important;
  ${breakpoints.mobile} {
    font-size: ${FontScale(6.175)} !important;
  }
`;

const BlobContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 0;
  height: 210px;

  .bottom {
    margin: 0;
    padding: 0;
    transform: translateY(-8px);
    background: ${colors.ivy};
    flex-grow: 1;
  }
`;
const PrimaryContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: ${depth.normal};
  transition: transform 200ms ease;
  ${breakpoints.mobile} {
    transform: scale(0.7) translate(240px, -55px);
    width: 200%;
  }
  ${breakpoints.tablet} {
    transform: translate(235px, -35px);
  }
  ${breakpoints.desktop} {
    transform: translate(-35px, -35px);
  }

  svg {
    height: fit-content;
    width: 100%;
  }
`;

const Scene = styled.div`
  position: relative;
  z-index: ${depth.elevated - 1};
  max-width: ${MAX_WIDTH};
  min-height: 500px;
  padding: 0 ${SpaceScale(5)};
  margin: ${SpaceScale(8)} auto 0;

  svg {
    display: block;
    overflow: visible;
    width: 100%;
  }

  ${breakpoints.mobile} {
    margin: 0 auto;
  }
`;

const Content = styled.div`
  max-width: 600px;
  padding-bottom: ${SpaceScale(8)};
  position: relative;
  z-index: ${depth.high};
`;

export default Intro;
