import Body from "components/Body";
import Image from "next/image";
import Flex from "components/Flex";
import Heading from "components/Heading";
import Section from "components/Section";
import Subheading from "components/Subheading";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { breakpoints, SpaceScale } from "theme";

const HowItWorks: FC = () => {
  return (
    <Section className={ContainerStyles}>
      <Container justify="space-between">
        <Details>
          <header>
            <Subheading color="ocean">How it works</Subheading>
            <Heading level={2} color="navy">
              Over one billion people have a smartphone but don't have access to
              basic financial services
            </Heading>
            <Body>
              At GiveCrypto, we not only identify recipients in need and
              distribute funds to theme - we also connect those people to a
              truly open financial system. All it takes is access to the
              internet.
            </Body>
          </header>
        </Details>
        <Cards>
          <Image
            src="/images/how-it-works/how-it-works@2x.jpg"
            alt="Explanation of how it works"
            width={1075}
            height={613}
            priority
          />
        </Cards>
      </Container>
    </Section>
  );
};

const Cards = styled.div`
  ${breakpoints.mobile} {
    display: none;
  }
`;

const Container = styled(Flex)`
  flex-wrap: wrap;

  ${breakpoints.desktop} {
    flex-wrap: nowrap;
  }
`;

const Details = styled.div`
  max-width: unset;
  width: 100%;
  ${breakpoints.desktop} {
    width: 50%;
    max-width: 480px;
  }
`;

const ContainerStyles = css`
  padding: ${SpaceScale(7)} 0;
  /* background-image: url("/images/how-it-works/how-it-works@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right ${SpaceScale(8)};
  background-size: contain; */

  ${breakpoints.mobile} {
    padding-bottom: ${SpaceScale(4)};
  }
`;

export default HowItWorks;
