import { NextPage } from "next";
import Head from "next/head";
import React from "react";
import { Intro, GlobalImpact, Video, HelpApp, HowItWorks } from "./components";
import GiveCTA from "components/Give/GiveCTA";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Home | GiveCrypto.org</title>
      </Head>
      <Intro />
      <GlobalImpact />
      <HowItWorks />
      <HelpApp />
      <Video />
      <GiveCTA />
    </>
  );
};

export default Home;
