import Flex from "components/Flex";
import { GiveTrigger } from "components/Give";
import Heading from "components/Heading";
import Section from "components/Section";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { FontScale, SpaceScale } from "theme";

const GiveCTA: FC = () => {
  return (
    <>
      <Section background="blue" color="white">
        <StyledFlex center column>
          <Heading className={HeadingStyles} color="white">
            Ready to put your crypto to work for good?
          </Heading>
          <GiveTrigger nature="blue" inverted shadow kind="coinbase" />
        </StyledFlex>
      </Section>
    </>
  );
};

const HeadingStyles = css`
  font-size: ${FontScale(5.75)} !important;
  margin-bottom: ${SpaceScale(5)};
  max-width: 440px;
  text-align: center;
`;

const StyledFlex = styled(Flex)`
  min-height: 500px;
`;

export default GiveCTA;
