import Color from "color";
import Section from "components/Section";
import { styled } from "linaria/react";
import React, { FC } from "react";
import { colors, SpaceScale } from "theme";
import YoutubePlayer from "./components/YoutubePlayer";

const Video: FC = () => {
  return (
    <Container>
      <Section>
        <YoutubePlayer
          title="How GiveCrypto.org works"
          src="https://www.youtube-nocookie.com/embed/NdFEmXQe-HE"
        />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
  background: ${colors.blue};
  background: linear-gradient(160deg, ${colors.paper} 50%, ${colors.blue} 50%),
    linear-gradient(
      160deg,
      ${Color(colors.sky).alpha(0).string()} 48%,
      ${colors.sky} 50%
    );
`;

export default Video;
